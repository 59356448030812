// Navbar
html.main-navbar-open {
    overflow: hidden;
}

.menu-open {
    .navbar {
        .navbar-wrapper {
            visibility: visible;
            transform: translateY(0);
            transition: transform $transition-time $transition-ease;

            .nav-bg-image {
                display: block;
                z-index: -1;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
    }
}

.navbar {
    .navbar-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 75px;
        left: 0;
        right: 0;
        background-color: $white;
        visibility: hidden;
        transform: translateY(100%);
        transition: transform $transition-time $transition-ease;
        z-index: $zindex-fixed;

        @include media-breakpoint-up(sm) {
            top: 85px;
        }

        @include media-breakpoint-up (lg) {
            visibility: visible;
            top: 0;
            transform: translateY(0);
            height: auto;
            padding: 0 20px;
        }

        .nav-bg-image {
            display: none;
            position: absolute;
            width: 233px;
            right: -50px;
            bottom: 60px;
            opacity: 0.1;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    .navbar-collapse {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 40px 0 0;
        height: calc(100vh - 126px);
        z-index: 1;

        .navbar-nav {
            display: block;
        }


        @include media-breakpoint-up(lg) {
            padding: 0;
            height: auto;
        }
    }

    .navbar-nav {
        .nav-link,
        .dropdown-title{
            font-family: "ChaletComprime-MilanSixty", sans-serif;
            font-size: $font-size-mobile-nav;
            line-height: 1;
            padding: 10px 0;
        }

        .nav-item {
            display: flex;
            justify-content: center;
        }

        .dropdown {
            position: static;

            .dropdown-menu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 0;
                padding: 40px 0;
                overflow-y: scroll;
                overflow-x: hidden;

                .dropdown-title {
                    display: flex;
                    flex-direction: column;

                    .dropdown-title-image {
                        margin-top: 10px;
                        display: block;
                        width: 38px;
                        height: 21px;
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0;

                    .nav-link,
                    .dropdown-title {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .dropdown-menu-item {
                        .dropdown-item-image {
                            display: none;
                        }

                        .nav-link {
                            font-size: $font-size-mobile-nav-second;
                        }
                    }

                    .dropdown-close {
                        .dropdown-item {
                            font-size: 1.063rem;
                            color: $gray-300;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .back-icon {
                                fill: $dark;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.navbar-expand-lg .navbar-collapse {
            background: $white;
        }

        &.navbar-expand-lg {
            .navbar-nav {
                align-items: flex-end;

                .nav-item {
                    &.active {
                        .nav-link {
                            &::after {
                                right: 0;
                                transition: right $transition-time $transition-ease;
                            }
                        }

                        .dropdown-menu-item {
                            .nav-link {
                                &::after {
                                    right: 100%;
                                }
                            }
                        }
                    }

                    .nav-link {
                        line-height: 1.2;
                        font-size: 1.7rem;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 10px;
                            height: 2px;
                            left: 0;
                            right: 100%;
                            background-color: $dark;
                            transition: right $transition-time $transition-ease;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $black;

                            &::after {
                                right: 0;
                                transition: right $transition-time $transition-ease;
                            }
                        }

                        @include media-breakpoint-up(xl) {
                            font-size: 2rem;
                        }
                    }
                }

                .dropdown-menu {
                    left: 50%;
                    top: 105px;
                    right: unset;
                    bottom: unset;
                    transform: translate(-50%, 0);
                    padding: 0 30px;
                    background: $white;
                    border: 0;
                    width: 100vw;
                    height: calc(100vh - 105px);
                    position: fixed;
                    overflow-y: visible;
                    overflow-x: visible;

                    .dropdown-close {
                        align-self: flex-end;

                        @media (min-width: 1700px) {
                            position: relative;
                            top: -100px;
                        }

                        .dropdown-close-btn {
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            margin: 20px 0;
                            padding: 0;

                            .text {
                                position: relative;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    bottom: -5px;
                                    height: 2px;
                                    left: 0;
                                    right: 100%;
                                    background-color: $dark;
                                    transition: right $transition-time $transition-ease;
                                }
                            }

                            .close-icon {
                                display: block;
                                width: 26px;
                                height: 26px;
                                position: relative;
                                margin-right: 10px;
                                flex-shrink: 0;

                                span {
                                    display: block;
                                    position: absolute;
                                    height: 2px;
                                    width: 100%;
                                    opacity: 1;
                                    left: 0;
                                    background-color: $dark;
                                    transform: rotate(0deg);
                                    transition: 0.25s ease-in-out;
                                    top: 13px;
                                }

                                span:nth-child(1) {
                                    transform: rotate(45deg);
                                }

                                span:nth-child(2) {
                                    transform: rotate(-45deg);
                                }
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                background-color: transparent;
                                color: $black;

                                .text {
                                    &::after {
                                        right: 0;
                                        transition: right $transition-time $transition-ease;
                                    }
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(xl) {
                        top: 150px;
                        height: calc(100vh - 150px);
                    }


                    &.show {
                        animation: dropdown-fade 250ms ease-out forwards;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        ul {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            max-width: 760px;
                            width: 100%;
                            align-items: center;

                            @include media-breakpoint-up(xl) {
                                max-width: 1015px;
                            }
                        }

                        .dropdown-menu-item {
                            display: inline-flex;
                            width: 223px;
                            height: 223px;
                            margin: 15px;
                            flex-shrink: 0;

                            .dropdown-menu-item-inner {
                                width: 100%;
                                height: 100%;
                                overflow: hidden;

                                &.color_mint {
                                    a {
                                        background-color: $primary;
                                    }
                                }

                                &.color_darkgrey {
                                    a {
                                        background-color: $darkgray;
                                    }
                                }

                                &.color_lightgrey {
                                    a {
                                        background-color: $lightgray;
                                    }
                                }

                                &.color_gold {
                                    a {
                                        background-color: $gold;
                                    }
                                }

                                &.wave {
                                    a {
                                        background-image: $background-wave;
                                        background-size: 1000px;
                                        background-position: 0;
                                        transition: background-position ($transition-time) $transition-ease;

                                        &:hover {
                                            background-position: -120px;
                                            transition: background-position ($transition-time) $transition-ease;
                                        }
                                    }

                                }
                            }

                            a {
                                &.nav-link {
                                    width: 100%;
                                    height: 100%;
                                    position: relative;

                                    &::after {
                                        display: none;
                                    }

                                    .dropdown-item-image {
                                        display: block;
                                        transform: scale(1);
                                        transition: transform $transition-time $transition-ease;

                                        .figure-img {
                                            margin: 0;
                                        }
                                    }
                                }

                                .teaser-title {
                                    position: absolute;
                                    bottom: 15px;
                                    left: 0;
                                    font-size: 2.375rem;
                                    text-transform: uppercase;
                                    line-height: 1;
                                    background-color: unset;
                                    margin: 0 15px;
                                    display: inline-flex;
                                    white-space: normal;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        height: 2px;
                                        left: 0;
                                        right: 100%;
                                        background-color: $dark;
                                        transition: right $transition-time $transition-ease;
                                    }

                                    &.white {
                                        color: $white;

                                        &::before {
                                            background: $white;
                                        }
                                    }

                                    &.dark {
                                        color: $dark;

                                        &::before {
                                            background: $dark;
                                        }
                                    }
                                }

                                &:hover,
                                &:focus,
                                &:active,
                                &.active {
                                    background-color: unset;
                                    color: $black;

                                    .dropdown-item-image {
                                        transform: scale(1.05);
                                        transition: transform $transition-time $transition-ease;
                                    }

                                    .teaser-title {
                                        &::before {
                                            right: 0;
                                            transition: right $transition-time $transition-ease;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-item {
            .fa-chevron-down {
                position: relative;
                top: -1px;
                margin-left: 0.5rem;
            }
        }
    }
}

@keyframes dropdown-fade {
    from {
        opacity: 0;
        transform: translate(-50%, -1rem);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast:none) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        min-width: 600px;
    }
}