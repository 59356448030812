// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
}
.gallery-item {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
}


figure.image {
    margin-bottom: $grid-gutter-width;

    img,
    picture {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }

    a {
        picture {
            &:hover {
                img {
                    transform: scale(1.1);
                    transition: transform $transition-time $transition-ease;
                }
            }
        }
    }

    picture {
        overflow: hidden;
    }

    img {
        transform: scale(1);
        transition: transform $transition-time $transition-ease;
    }
}


// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: calc(100% / 2);
}
.gallery-item-size-3 {
    width: calc(100% / 3);
}
.gallery-item-size-4 {
    width: calc(100% / 2);
    @include media-breakpoint-up('md') {
        width: calc(100% / 4);
    }
}

