/* Splide Core Files */
@import '@splidejs/splide/src/sass/core/foundation/variables';
@import '@splidejs/splide/src/sass/core/foundation/animations';

@import '@splidejs/splide/src/sass/core/object/objects/root';
@import '@splidejs/splide/src/sass/core/object/objects/container';
@import '@splidejs/splide/src/sass/core/object/objects/list';
@import '@splidejs/splide/src/sass/core/object/objects/pagination';
@import '@splidejs/splide/src/sass/core/object/objects/slide';
@import '@splidejs/splide/src/sass/core/object/objects/slider';
//@import '@splidejs/splide/src/sass/core/object/objects/spinner';
@import '@splidejs/splide/src/sass/core/object/objects/track';

@import '@splidejs/splide/src/sass/core/object/modifiers/draggable';
@import '@splidejs/splide/src/sass/core/object/modifiers/fade';
//@import '@splidejs/splide/src/sass/core/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/core/object/modifiers/ttb';

/* Splide Default Style Files */
@import '@splidejs/splide/src/sass/themes/default/foundation/variables';

//@import '@splidejs/splide/src/sass/themes/default/object/objects/arrow'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/pagination'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/progress';

@import '@splidejs/splide/src/sass/themes/default/object/modifiers/nav';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/ttb';


.element-header {
    margin-bottom: 20px;
}

.splide {
    &__track {
        overflow: visible;

        .image {
            overflow: hidden;

            img {
                width: 100%;
                transform: scale(1);
                transition: transform $transition-time $transition-ease;
            }

            .figure-caption {
                display: none;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.05);
                    transition: transform $transition-time $transition-ease;
                }
            }
        }
    }

    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 0;
        padding: 0;
        background: transparent;
        transition: fill $transition-time $transition-ease;

        svg {
            width: 25px;
            height: 25px;

            @include media-breakpoint-up (lg) {
                width: 40px;
                height: 40px;
            }
        }

        @include media-breakpoint-up (lg) {
            width: 40px;
            height: 40px;
        }

        &--next {
            transform: rotate(180deg);
        }

        &[disabled] {
            fill: $lightgray;
        }

        &:hover {
            cursor: pointer;

            &:not(:disabled) {
                fill: $mint;
            }
        }

        &:focus {
            outline: none;

            &:not(:disabled) {
                fill: $mint;
            }
        }
    }
}


