@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
//Bootstrap Optional
//@import "bootstrap/scss/root";
//@import "bootstrap/scss/reboot";
//@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
//@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
//@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/print";

//Bootstrap Optional
@import "BootstrapCustom/images";
@import "BootstrapCustom/transitions";
@import "BootstrapCustom/list-group";
@import "BootstrapCustom/modal";

// Custom Styles
@import "textpic";
@import "texticon";
@import "gallery";
@import "magnific";
@import "uploads";
@import "cards";
@import "navbar";
@import "slider";
@import "forms";

@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
        background-color: $value;
    }
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}

// Layout
.container {
    padding-left: 25px;
    padding-right: 25px;

    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

// wave under headlines
.style-wave {
    &::after {
        content: '';
        background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Icons/welle.svg");
        width: 58px;
        height: 30px;
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 10px 0 20px;
    }

    &.text-center {
        display: block;
        &::after {
            margin: 10px auto 20px;
        }
    }
}

// Footer
.footer {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

     @include media-breakpoint-up(xl) {
        .container {
            max-width: 1725px;

            .footer-row {
                max-width: 1100px;
                margin: 0 auto;
            }
        }
    }

    .line {
        display: block;
        max-width: 400px;
        height: 1px;
        background-color: $lightgray;
        margin: 0 auto 30px;

        @include media-breakpoint-up (md) {
            margin: 0 auto 50px;
        }

        @include media-breakpoint-up (lg) {
            margin: 0 auto 70px;
        }
    }

    &-content {
        color: $dark;
        font-size: 0.688rem;
        text-transform: uppercase;
        padding: 30px 0 15px;

        @include media-breakpoint-up (md) {
            padding: 30px 0;
        }

        @include media-breakpoint-up(lg) {
            font-size: 0.938rem;
            text-transform: none;
            padding: 40px 0;
        }

        .footer-col{
            width: 100%;
            flex-shrink: 0;
            flex-basis: unset;
        }

        .footer-address {
            margin-bottom: 20px;
            @include media-breakpoint-up (md) {
                margin-bottom: 30px;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 50px;
            }


            @media (min-width: 1600px) {
                margin-bottom: 80px;
            }

            .badge-address {
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-up(xl) {
                    justify-content: flex-start;
                }

                .contact {
                    text-transform: none;

                    a {
                        color: $dark;

                        &:hover {

                            color: $black;
                        }
                    }
                }

                .badge-footer {
                    display: flex;
                    align-items: center;
                    width: 85px;
                    height: 85px;
                    margin-right: 30px;
                    flex-shrink: 0;

                    @include media-breakpoint-up (xl) {
                        width: 125px;
                        height: 125px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            .partners {
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-up(xl) {
                    justify-content: flex-start;
                }

                .bad-ischl {
                    display: block;
                    width: 97px;
                    margin-left: 20px;

                    @include media-breakpoint-up (xl) {
                        width: 119px;
                    }
                }

                .salzkammergut {
                    width: 185px;
                    margin: 0 20px;

                    @include media-breakpoint-up (xl) {
                        width: 205px;
                    }
                }

                .originale {
                    display: block;
                    width: 85px;
                    margin: 0 20px;

                    @include media-breakpoint-up (xl) {
                        width: 98px;
                    }
                }

                .fallstaff,
                .land-ooe {
                    display: block;
                    width: 73px;

                    @include media-breakpoint-up (xl) {
                        width: 79px;
                    }
                }

                img {
                    width: 100%;
                    filter: grayscale(1);
                    transition: filter $transition-time $transition-ease;
                }
            }
        }

        .footer-map {
            @include media-breakpoint-up (md) {
                width: 50%;
            }

            @media (min-width: 1600px) {
                max-width: 550px;
            }

            .karte-lakes {
                margin: 15px 0;

                @include media-breakpoint-up (md) {
                    margin: 0;
                }
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }

    &-bottom {
        background-color: $dark;
        font-size: 0.813rem;

        @include media-breakpoint-up(lg) {
            font-size: 0.875rem;
        }

        .list-inline {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }
        }

        .list-inline-item {
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            a {
                color: $white;

                @media (min-width: 1600px) {
                    color: $dark;
                }
            }
        }

        .list-inline-item:not(:last-child) {
            margin-right: 15px;

            @include media-breakpoint-up(lg) {
                margin-right: 25px;
            }

            @include media-breakpoint-up(xl) {
                margin-right: 40px;
            }
        }

        @media (min-width: 1600px) {
            background-color: $mint;
        }
    }

    &-sociallinks {
        font-size: 2rem;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-start;

            .container {
                max-width: 400px;
                margin: 0;
            }
        }

        .list-inline {
            display: flex;
            justify-content: flex-start;

            .list-inline-item {
                margin-bottom: 0;

                a {
                    color: $white;
                    display: flex;

                    .svg-icon {
                        width: 23px;
                        height: 23px;
                        transform: scale(1);
                        transition: transform $transition-time $transition-ease;

                        @include media-breakpoint-up(lg) {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &:hover {
                        .svg-icon {
                            transform: scale(1.1);
                            transition: transform $transition-time $transition-ease;
                        }
                    }

                    @media (min-width: 1600px) {
                        color: $dark;
                    }
                }

                &.route {
                    a {
                        display: flex;
                        align-items: center;
                    }

                    span {
                        font-size: 13px;
                        margin-right: 10px;

                        @include media-breakpoint-up(lg) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


//
// Scrolltop
//
.scroll-top {
    opacity: 0;
    z-index: 1050;
    background-color: $mint;
    color: $white;
    bottom: -1px;
    left: -1px;
    position: fixed;
    display: none;
    transform: translateX(-100vw);
    transition: all ($transition-time * 3) $transition-ease;

    a {
        text-decoration: none;
    }

    @media (min-width: 1600px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: $headings-font-family;

        .langswitcher {
            height: 51px;
            justify-content: center;
            align-items: center;
            margin: 0 !important;

            .language-menu {
                .langnav-item {
                    &:not(.current) {
                        width: 0;
                        opacity: 0;
                    }

                    a {
                        font-size: 1.8rem;
                        text-transform: lowercase;
                        display: flex;

                        span {
                            width: 0;
                            opacity: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .mobile-icon-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px;

            .w4-voucher-link {
                margin: 0 !important;
            }

            .w4-booking-link,
            .w4-mobile-menu-link {
                display: flex;
                flex-direction: column;
                align-items: center;

                .icon {
                    width: 3em;
                    height: 3em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    fill: $dark;
                    transform: scale(1);
                    transition: transform $transition-time $transition-ease;
                }

                .text {
                    opacity: 0;
                    height: 0;
                    width: 0;
                    text-transform: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.8rem;
                }

                &:hover {
                    .icon {
                        fill: $gray-300;
                        transform: scale(1.1);
                        transition: transform $transition-time $transition-ease, fill $transition-time $transition-ease;
                    }
                    .text {
                        color: $gray-300;
                    }
                }
            }

            .line {
                background-color: $gray-300;
                height: 1px;
                width: 40px;
                display: block;
            }
        }

        &.mouseenter-animation {
            animation: showLinks .4s ease-in-out forwards !important;

            .line {
                animation: moreMargin .4s ease-in-out forwards !important;
            }

            .mobile-icon-buttons {
                .langswitcher {
                    .language-menu {
                        .langnav-item {
                            animation: showLanguageText .4s ease-in-out forwards !important;

                            a {
                                span {
                                    animation: showText .4s ease-in-out forwards !important;
                                    margin: 0 10px;
                                }
                            }

                            &.current {
                                display: block;
                            }
                        }
                    }
                }

                .w4-booking-link,
                .w4-mobile-menu-link {
                    .text {
                        animation: showText .4s ease-in-out forwards !important;
                    }
                }
            }
        }

        &.mouseleave-animation {
            animation: hideLinks .4s ease-in-out forwards !important;

            .line {
                animation: lessMargin .4s ease-in-out forwards !important;
            }

            .mobile-icon-buttons {
                .w4-booking-link,
                .w4-mobile-menu-link {
                    .text {
                        animation: hideText .4s ease-in-out forwards !important;
                    }
                }
            }
        }
    }

    .scroll-top-link {
        overflow: hidden;
        width: 3em;
        height: 3em;
        display: block;
        position: relative;
        color: $dark;
        transform: scale(1);
        transition: transform $transition-time $transition-ease;

        &:active,
        &:focus,
        &:hover {
            transform: scale(1.1);
            transition: transform $transition-time $transition-ease;
        }
        .scroll-top-icon {
            @extend .css-arrow;
        }
    }

    &.scroll-top-visible {
        transform: translateX(0);
        opacity: 1;
        transition: all ($transition-time * 3) $transition-ease;
    }
}

.css-arrow {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:before {
        content: '';
        position: absolute;
        height: 30%;
        width: 30%;
        border-top: 1px solid;
        border-left: 1px solid;
        top: 55%;
        left: 55%;
        transform: translate(-50%, -50%);
    }
}

//Position Chat Plugin Small Screens
@media (max-width: 1599.99px) {
    .woot-widget-bubble {
        bottom: 60px!important;
    }
}
