// Uploads
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/media";

.media-list {
    @include list-unstyled;
}


.media {
    flex-direction: column;
    list-style: none;
    margin-bottom: 30px;

    &-left {
        width: 100%;
    }

    &-body {
        width: 100%;
    }

    &-heading {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom:0;

        > a {
            display: inline-flex;
            align-items: center;
        }
    }
}

.uploads {
    &-fileicon {
        margin-right: 0.25rem;

        svg {
            width: 1em;
            height: 1em;
        }
    }

    &-filename {
        color: $black;
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        font-weight: 600;
    }

    &-imageinfos {
        display: flex;
        justify-content: flex-start;
        line-height: 1;
        margin-bottom: 10px;
        color: $black;

        a {
            text-decoration: none;
        }
    }

    &-filesize {
    }
}
